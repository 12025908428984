<template>
  <!-- TODO: refatorar para um unico componente que exibe logs por colunas -->
  <ReportsHistory
    pageTitle="Contas de usuário"
    :reports="reports"
    :loading="loading"
    @changeShowTable="changeShowTable"
    :showDataTable="showDataTable"
    :period-selected="periodSelected"
    @reload:reports="getReports"
    @update:period-selected="
      handleUpdatePeriod('periodSelected', $event, getReports)
    "
  />
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { subtractDays } from "@/helpers/services/utils";
import { errorMessages } from "@/helpers/variables/snackbarMessages";
import {
  SUITE_DEV_PROJECT_NAME,
  SUITE_PROD_PROJECT_NAME,
} from "@/helpers/variables/backendConstants";
import ReportsHistory from "@/components/logs/ReportsHistory.vue";

export default {
  name: "AccountsReports",

  components: {
    ReportsHistory,
  },

  data() {
    return {
      reports: [],
      loading: false,
      errorMessages,
      showDataTable: false,
      periodSelected: { textKey: "common.past7Days", value: subtractDays(7) },
    };
  },
  computed: {
    ...mapGetters(["company", "isResellerPage"]),
  },

  methods: {
    ...mapMutations(["setSnackBar"]),

    async changeShowTable() {
      this.showDataTable = !this.showDataTable;
      if (!this.reports.length) {
        await this.getReports();
      }
    },

    getReports() {
      this.loading = true;

      let companyKey = "";
      if (this.isResellerPage) {
        companyKey = this.$route.query.key;
      }

      const from_date = this.periodSelected.value;
      const url = `${process.env.VUE_APP_BIG_QUERY_API}/get-logs?from_date=${from_date}`;

      const config = {
        params: {
          company_key: companyKey ? companyKey : this.company.key,
          query: "accounts",
          project_name:
            process.env.NODE_ENV !== "production"
              ? SUITE_DEV_PROJECT_NAME
              : SUITE_PROD_PROJECT_NAME,
        },
      };

      this.$axios
        .get(url, config)
        .then(({ data }) => {
          this.reports = data;
        })
        .catch((err) => {
          this.setSnackBar({
            message: this.errorMessages.LOG_ERROR,
            color: "error",
            show: true,
          });
          console.error("getReports()", err);
        })
        .finally(() => (this.loading = false));
    },

    async handleUpdatePeriod(periodSelected, event, reportGetter) {
      this[periodSelected] = event;
      await reportGetter();
    },
  },
  async beforeMount() {
    if (!this.isResellerPage) {
      await this.getReports();
    }
  },
};
</script>

<style></style>
