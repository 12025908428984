<template>
  <ReportsHistory
    pageTitle="Relatórios gerais"
    :reports="reports"
    :loading="loading"
    @changeShowTable="changeShowTable"
    :showDataTable="showDataTable"
    :period-selected="periodSelected"
    @reload:reports="getCompanyReports"
    @update:period-selected="
      handleUpdatePeriod('periodSelected', $event, getCompanyReports)
    "
  />
</template>
<script>
import { mapGetters } from "vuex";
import { subtractDays } from "@/helpers/services/utils";
import ReportsHistory from "@/components/logs/ReportsHistory.vue";

export default {
  name: "ReportsGeneral",
  components: {
    ReportsHistory,
  },

  data() {
    return {
      reports: [],
      loading: false,
      pageHeight: 0,
      showDataTable: false,
      periodSelected: { textKey: "common.past7Days", value: subtractDays(7) },
    };
  },
  computed: {
    ...mapGetters(["token", "clientToken", "isResellerPage"]),
  },
  methods: {
    async changeShowTable() {
      this.showDataTable = !this.showDataTable;
      if (!this.reports.length) {
        await this.getCompanyReports();
      }
    },

    async getCompanyReports() {
      this.loading = true;

      let clientToken = "";
      if (this.isResellerPage && this.clientToken) {
        clientToken = `Bearer ${this.clientToken}`;
      }

      const from_date = this.periodSelected.value;
      const url = `${process.env.VUE_APP_API_BASE_URL}/company/history?from_date=${from_date}`;

      const config = {
        headers: {
          Authorization: clientToken ? clientToken : this.token,
        },
      };

      await this.$axios
        .get(url, config)
        .then(({ data }) => {
          this.reports = data;
          this.reports.concat(data);
        })
        .catch((err) => {
          this.showSnackBarError = true;
          console.error("getCompanyReports()", err);
        })
        .finally(() => (this.loading = false));
    },

    handleResize() {
      this.pageHeight = window.innerHeight;
    },

    async handleUpdatePeriod(periodSelected, event, reportGetter) {
      this[periodSelected] = event;
      await reportGetter();
    },
  },
  async beforeMount() {
    if (!this.isResellerPage) {
      await this.getCompanyReports();
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
