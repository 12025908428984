<template>
  <v-select
    class="mb-1"
    dense
    :width="width"
    :items="periods"
    v-model="period"
    item-text="text"
    :label="label"
    outlined
    :style="`max-width: ${width}; height: ${height}`"
    :multiple="false"
    :disabled="loading"
    :loading="loading"
    @click.stop=""
    return-object
  >
    <template v-slot:prepend-inner>
      <v-icon v-text="'mdi-calendar-month'" class="mr-2" />
    </template>
  </v-select>
</template>
<script>
import { subtractMonths } from "@/helpers/services/utils";
export default {
  name: "SelectPeriod",
  props: {
    label: { type: String, default: "Período" },
    width: { type: String, default: "250px" },
    height: { type: String, default: "35px" },
    loading: { type: Boolean, default: false },
    value: { type: Object, required: true },
  },
  data() {
    return {
      periods: [
        { text: "Ùltimo mês", value: subtractMonths(1) },
        { text: "Ùltimos 3 meses", value: subtractMonths(3) },
        { text: "Últimos 6 meses", value: subtractMonths(6) },
        { text: "Todo período", value: "" },
      ],
    };
  },
  computed: {
    period: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
  },
};
</script>
