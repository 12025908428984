<template>
  <div>
    <v-skeleton-loader
      :width="width"
      :height="height"
      v-if="loading"
      class="px-2 py-2"
      type="image"
    ></v-skeleton-loader>
    <v-card
      :width="width"
      :height="height"
      outlined
      class="px-2 py-2"
      :color="color"
      v-else
      align="start"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle :class="textClass">
            {{ label }}
          </v-list-item-subtitle>
          <v-list-item-title :class="`text-h3 font-weight-bold ${textClass}`">
            {{ value }}
          </v-list-item-title>
          <v-list-item-subtitle :class="textClass">
            {{ description }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon v-if="icon" v-text="icon"></v-icon>
          <slot name="icon"> </slot>
        </v-list-item-icon>
      </v-list-item>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "Slat",
  props: {
    width: { type: String, default: "300px" },
    height: { type: String, default: "140px" },
    label: { type: String, required: true },
    value: { type: [String, Number], required: true },
    description: { type: String },
    icon: { type: String },
    color: { type: String },
    textClass: { type: String, default: "white--text" },
    loading: { type: Boolean, default: false },
  },
};
</script>
