<template>
  <ReportsHistory
    pageTitle="Assinaturas de e-mail"
    :reports="reports"
    :loading="loading"
    @changeShowTable="changeShowTable"
    :showDataTable="showDataTable"
    :period-selected="periodSelected"
    @reload:reports="getSignatureEmailReports"
    @update:period-selected="
      handleUpdatePeriod('periodSelected', $event, getSignatureEmailReports)
    "
  />
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { subtractDays } from "@/helpers/services/utils";
import ReportsHistory from "@/components/logs/ReportsHistory.vue";

export default {
  name: "ReportsSignatures",

  components: {
    ReportsHistory,
  },

  data() {
    return {
      reports: [],
      loading: false,
      showDataTable: false,
      periodSelected: { textKey: "common.past7Days", value: subtractDays(7) },
    };
  },
  computed: {
    ...mapGetters(["token", "clientToken", "isResellerPage"]),

    ...mapMutations(["setSnackBar"]),
  },
  methods: {
    async changeShowTable() {
      this.showDataTable = !this.showDataTable;
      if (!this.reports.length) {
        await this.getSignatureEmailReports();
      }
    },

    async getSignatureEmailReports() {
      this.loading = true;

      let clientToken = "";
      if (this.isResellerPage && this.clientToken) {
        clientToken = `Bearer ${this.clientToken}`;
      }

      const from_date = this.periodSelected.value;
      const url = `${process.env.VUE_APP_API_BASE_URL}/company/history/signature?from_date=${from_date}`;

      const config = {
        headers: {
          Authorization: clientToken ? clientToken : this.token,
        },
      };

      await this.$axios
        .get(url, config)
        .then(({ data }) => {
          this.reports = data;
        })
        .catch((err) => {
          this.setSnackBar({
            message:
              "Erro ao recuperar os registros de assinatura, tente novamente.",
            color: "error",
            show: true,
          });
          console.error("getSignatureEmailReports()", err);
        })
        .finally(() => (this.loading = false));
    },

    async handleUpdatePeriod(periodSelected, event, reportGetter) {
      this[periodSelected] = event;
      await reportGetter();
    },
  },
  async beforeMount() {
    if (!this.isResellerPage) {
      await this.getSignatureEmailReports();
    }
  },
};
</script>

<style></style>
