<template>
  <v-card
    :class="`mx-5 my-5 elevation-0 ${showTable ? 'pb-4' : 'pb-0'}`"
    outlined
  >
    <v-card-title
      :style="showTable ? 'border-bottom: 1px solid rgba(0, 0, 0, 0.12)' : ''"
    >
      <v-row>
        <v-col cols="12" class="py-0 d-flex align-center" sm="12" lg="9">
          <v-card-title class="px-1"> Histórico de cobrança </v-card-title>
          <v-spacer v-if="$vuetify.breakpoint.smAndDown" />
          <v-btn
            v-if="$vuetify.breakpoint.smAndDown"
            @click="showTable = !showTable"
            :class="`ml-auto my-auto`"
            icon
            large
          >
            <v-icon
              v-text="showTable ? 'mdi-chevron-up' : 'mdi-chevron-down'"
              size="28"
            />
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>
        <!--FILTER MENU-->
        <v-col
          cols="12"
          sm="12"
          lg="3"
          class="d-flex align-center py-0"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <v-autocomplete
            dense
            v-if="showTable"
            :items="periods"
            :filter="customFilter"
            v-model="period"
            item-text="name"
            label="Período"
            outlined
            style="max-height: 35px; max-width: 230px"
            class="mr-3"
          >
            <template v-slot:append>
              <v-icon v-text="'mdi-menu-down'" />
              <v-icon v-text="'mdi-calendar'" />
            </template>
          </v-autocomplete>
          <v-btn
            @click="showTable = !showTable"
            :class="`ml-auto my-auto`"
            icon
            large
          >
            <v-icon
              v-text="showTable ? 'mdi-chevron-up' : 'mdi-chevron-down'"
              size="28"
            />
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text v-if="showTable && $vuetify.breakpoint.smAndDown" class="mt-6">
      <v-autocomplete
        dense
        :items="periods"
        :filter="customFilter"
        v-model="period"
        item-text="name"
        label="Período"
        outlined
        style="max-height: 35px"
        hide-details
        class="mr-3"
      >
        <template v-slot:append>
          <v-icon v-text="'mdi-menu-down'" />
          <v-icon v-text="'mdi-calendar'" />
        </template>
      </v-autocomplete>
    </v-card-text>

    <v-card-text v-if="showTable" class="mx-0 px-0">
      <Calendar
        class="mt-8"
        v-if="showCalendar"
        :reseller="true"
        type="month"
        :maximumDate="today"
        minimumDate="2021-10-01"
        @selectedDate="customPeriod = $event"
      />

      <v-card-title class="font-weight-medium text-body-1 py-3 grey lighten-5">
        <v-row>
          <v-col class="pl-5"> Período </v-col>
          <v-col class="d-flex align-center mr-10 flex-wrap">
            <v-spacer />
            Saldo final
            <v-tooltip top v-if="$vuetify.breakpoint.smAndUp">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  size="24"
                  class="ml-1 mr-4"
                  color="accent"
                  v-bind="attrs"
                  v-on="on"
                  v-text="'mdi-information'"
                />
              </template>
              <span>
                Transações de empresas não faturáveis não contabilizarão<br />
                para o saldo final do faturamento
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-title>

      <v-expansion-panels v-model="panels" flat focusable tile multiple>
        <v-expansion-panel
          class="elevation-0"
          v-for="(transactionPeriod, index) in transactionPeriods"
          :key="index"
        >
          <v-expansion-panel-header
            :class="`title mx-auto d-flex align-center py-4 text-subtitle-1 font-weight-medium`"
            @click="
              !transactionPeriod.transactions &&
                getTransactionsByMonth(transactionPeriod)
            "
          >
            {{ transactionPeriod.label }}
            <v-spacer />
            <span class="ml-auto text-right mr-8 text-body-1">
              <span v-if="transactionPeriod.totalValue">{{
                formatMoney(transactionPeriod.totalValue)
              }}</span>
              <v-tooltip v-else left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-text="'mdi-reload'"
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      !transactionPeriod.transactions &&
                        getTransactionsByMonth(transactionPeriod)
                    "
                  />
                </template>
                <span>Clique para detalhar os valores</span>
              </v-tooltip>
            </span>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <div v-if="loadTransaction">
              <v-progress-linear
                indeterminate
                color="primary"
              ></v-progress-linear>
            </div>

            <div v-else-if="transactionPeriod.transactions">
              <Alert class="my-4" color="info" :prominent="false" :dense="true">
                Somente transações mensais podem ser editadas.
              </Alert>
              <v-simple-table
                mobile-breakpoint="900"
                v-if="transactionPeriod.transactions.length > 0"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th align-start class="pr-0">Período</th>
                      <th align-start class="pr-0">Plano</th>
                      <th align-start class="pr-0">Tipo</th>
                      <th align-start>Licenças</th>
                      <th align-start class="pr-0">Status da transação</th>
                      <th v-if="isConecta" align-start class="pr-0">
                        Faturável
                      </th>
                      <th
                        v-if="isConecta || isReseller"
                        align-end
                        class="text-right pr-0"
                      >
                        Comissão
                      </th>
                      <th
                        v-if="isConecta || isReseller"
                        align-end
                        class="text-right pr-0"
                      >
                        Desconto
                      </th>
                      <th v-if="isConecta" align-end class="text-right">
                        Parcelas
                      </th>
                      <th align-end class="text-right">Valor Total</th>
                      <th class="text-left"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(transaction, i) in transactionPeriod.transactions"
                      :key="i"
                    >
                      <td width="150px" class="pr-0">
                        {{
                          initEndDateFormat(
                            transaction.init_date,
                            transaction.end_date
                          )
                        }}
                      </td>
                      <td class="pr-0">
                        {{ transaction.title }}
                      </td>

                      <td width="50px" class="pr-0">
                        <v-chip x-small>{{
                          getPlanSubscriptionType(transaction.subscription_type)
                        }}</v-chip>
                      </td>
                      <td>
                        {{ transaction.licenses }}
                      </td>
                      <td style="white-space: nowrap" class="pr-0">
                        <v-icon
                          size="20"
                          class="mr-1"
                          :color="transaction['canceled'] ? 'red' : 'green'"
                        >
                          {{
                            transaction["canceled"]
                              ? "mdi-close-circle-outline"
                              : "mdi-check-circle-outline"
                          }}
                        </v-icon>
                        {{ transaction["canceled"] ? "Cancelado" : "Ativo" }}
                      </td>

                      <td v-if="isConecta" class="pr-0">
                        {{
                          transaction["is_billable"]
                            ? "Faturável"
                            : "Não faturável"
                        }}
                      </td>

                      <td
                        v-if="isConecta || isReseller"
                        style="white-space: nowrap"
                        :class="` pr-0 ${
                          transaction.partner_commission < 0 ? 'red--text' : ''
                        } text-right`"
                      >
                        {{
                          transaction.partner_commission ||
                          transaction.partner_commission == 0
                            ? `${formatMoney(transaction.partner_commission)}`
                            : `-`
                        }}
                      </td>
                      <td
                        v-if="isConecta || isReseller"
                        class="text-right pr-0"
                      >
                        {{
                          getDiscountPercentageValue(
                            transaction,
                            plans,
                            services
                          )
                        }}
                      </td>
                      <td class="text-right">
                        {{
                          transaction.installments > 1
                            ? showInstallmentsValues(transaction)
                            : "À vista"
                        }}
                      </td>

                      <td
                        style="white-space: nowrap"
                        v-if="
                          transaction.total_price ||
                          transaction.total_price == 0
                        "
                        :class="`text-right ${
                          transaction.total_price < 0 ? 'red--text' : ''
                        }`"
                      >
                        {{ formatMoney(transaction.total_price) }}
                      </td>
                      <td class="text-right green--text" v-else>
                        Transação aberta
                      </td>
                      <td class="px-0">
                        <v-card-actions class="pa-0">
                          <v-menu offset-y left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                data-testid="options"
                                icon
                                class="ml-auto"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon v-text="'mdi-dots-vertical'" />
                              </v-btn>
                            </template>
                            <v-list min-width="120px" dense class="py-0">
                              <v-list-item
                                v-if="
                                  isConecta &&
                                  transaction.subscription_type === 'FLEX' &&
                                  transaction.status !== 'TRIAL'
                                "
                                @click="
                                  openEditFinancialDialog(transaction, i, index)
                                "
                                class="text-subtitle-2"
                              >
                                Editar
                              </v-list-item>
                              <v-list-item
                                v-if="isConecta"
                                @click="openDialog(transaction, i, index)"
                                class="text-subtitle-2"
                              >
                                {{
                                  transaction["canceled"]
                                    ? "Reativar"
                                    : "Cancelar"
                                }}
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-card-actions>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div v-else class="text-center disabled--text text-italic">
                Nenhuma transação encontrada para este período.
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>

    <!--FAILURE SNACKBAR -->
    <Snackbar
      :show="showSnackBarFailure"
      color="error"
      :message="errorMessages.unknown"
      @close="showSnackBarFailure = false"
    />

    <!--SUCCESS SNACKBAR -->
    <Snackbar
      :show="showSnackBarSuccess"
      color="success"
      :message="successMessages.saves_changes"
      @close="showSnackBarSuccess = false"
    />

    <!--EDIT FINANCIAL TRANSACTION DIALOG-->
    <SimpleDialog
      id="edit-financial-transaction"
      title="Editar transação"
      :show="showEditFinancialDialog"
      :action-text="actionText"
      width="800"
      :disabledAction="!selectedPlan"
      @close="closeEditTransaction"
      @action="actionFunction"
    >
      <template v-slot:body>
        <v-stepper
          v-model="steps"
          flat
          class="elevation-0 justify-center d-block mt-2"
        >
          <v-stepper-header
            style="max-height: 40px"
            class="elevation-0 mx-auto font-weight-medium"
          >
            <v-stepper-step
              style="max-height: 40px"
              :complete="steps > 1"
              step="1"
              editable
              @click="steps = 1"
            >
              Editar
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
              style="max-height: 40px"
              :complete="steps == 2"
              step="2"
            >
              Dados de alteração
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mt-0" outlined>
                <v-card-title class="text-body-1 font-weight-medium">
                  Escolha o novo plano
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row class="my-0 py-0">
                    <v-col cols="3" class="my-auto py-0 font-weight-medium">
                      Planos elegíveis
                    </v-col>
                    <v-col class="my-0 py-0">
                      <v-select
                        class="pt-5"
                        :items="plansName"
                        :hide-selected="true"
                        persistent-placeholder
                        v-model="selectedPlan"
                        persistent-hint
                        hint="O novo plano deve ser um plano mensal e ativo"
                        placeholder="Selecionar um plano"
                        no-data-text="Plano não encontrado"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="2" class="pt-2">
              <v-row>
                <v-col class="headline font-weight-bold pb-2">
                  Confirme os dados da alteração
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  class="grey--text text--darken-4 font-weight-regular subtitle-1"
                >
                  <p class="mb-2">Novo Plano: {{ selectedPlan }}</p>

                  <p class="mb-2">
                    Inicio da cobrança: {{ newFinancialPlan["init_date"] }}
                  </p>
                  <p class="mb-2">
                    Fim da cobrança: {{ newFinancialPlan["end_date"] }}
                  </p>
                  <p class="mb-2">Licenças: {{ licenses }}</p>
                  <p class="mb-2">
                    Desconto: {{ newFinancialPlan["discount"] }}
                  </p>
                  <p class="mb-2">
                    Preço: {{ newFinancialPlan["price"] }}/usuário/mês
                  </p>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </template>
    </SimpleDialog>

    <!--CANCEL OR REACTIVATE FINANCIAL TRANSACTION DIALOG-->
    <SimpleDialog
      id="financial-transaction-dialog"
      :title="`${planToEdit} - ${cancellationText}`"
      :show="dialogCancellation.showDialog"
      :action-text="cancellationActionText"
      width="650"
      :disabledAction="dialogCancellation.cancelValidationText !== planToEdit"
      @close="closeDialog"
      @action="dialogCancellation.func"
    >
      <template v-slot:body>
        <v-card class="mt-4" outlined>
          <v-card-title class="text-body-1 font-weight-medium">
            Cancelar transação
          </v-card-title>
          <v-divider />
          <v-card-text class="mt-2 mb-0 pb-0">
            A ação a seguir irá
            <span class="font-weight-bold text-uppercase">
              {{ cancellationText }}
            </span>
            a transação do plano "<span class="font-weight-bold">{{
              planToEdit
            }}</span
            >". Para confirmar esta alteração, digite o nome do plano no campo a
            seguir
          </v-card-text>
          <v-card-text>
            <v-row class="my-0 py-0">
              <v-col cols="3" class="my-auto py-0 font-weight-medium">
                Nome do plano
              </v-col>
              <v-col class="my-0 py-0">
                <v-text-field
                  placeholder="Escreva o nome do plano"
                  v-model="dialogCancellation.cancelValidationText"
                  :rules="rules"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </SimpleDialog>
  </v-card>
</template>

<script>
import { getDiscountPercentageValue } from "@/helpers/services/billing";
import { mapGetters } from "vuex";
import Calendar from "@/components/main/shared/Calendar";
import * as moment from "moment";
import {
  formatMoney,
  formatPeriod as initEndDateFormat,
  formatDate,
} from "@/helpers/services/utils";
import { formatPeriod } from "@/helpers/services/transactions";
import { SUBSCRIPTIONS, STATUS } from "@/helpers/variables/backendConstants";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";

export default {
  name: "EditFinancialTransaction",
  props: {
    activePlans: { type: Array },
    clientKey: { type: String, required: true },
    clientName: { type: String, required: true },
    licenses: { type: Number, required: true, default: 1 },
  },

  components: {
    Calendar,
  },

  data() {
    return {
      SUBSCRIPTIONS,
      STATUS,
      errorMessages,
      successMessages,
      panels: [],
      loadTransaction: false,
      period: "Últimos três meses",
      customPeriod: [],
      periods: [
        { name: "Este mês", id: 0 },
        { name: "Mês passado", id: 1 },
        { name: "Últimos três meses", id: 2 },
        { name: "Este ano", id: 3 },
        //{ name: "Ano anterior", id: 4 },
        { name: "Personalizado", id: 5 },
        // { name: 'Todo o período', id: 6 },
      ],
      showCalendar: false,
      today: moment().format("YYYY-MM-DD"),
      rules: [
        (value) =>
          value === this.planToEdit || "Digite o nome do plano corretamente.",
      ],
      transactionPeriods: [],

      showSnackBarSuccess: false,
      showSnackBarFailure: false,

      showEditFinancialDialog: false,

      newFinancialPlan: {
        init_transaction: "",
        end_transaction: "",
        price: 0,
        discount: 0,
        transctionKey: "",
        newCompanyPlanKey: "",
      },

      planToCancel: [],
      transactionId: 0,
      transactionIndex: 0,
      plansName: [],
      selectedPlan: "",
      planToEdit: "",
      steps: 1,
      actionsToEditTransaction: {
        1: {
          action: "next-step",
          text: "Continuar",
          func: this.nextStep,
        },
        2: {
          action: "edit-transaction",
          text: "Recalcular e salvar",
          func: this.editTransaction,
        },
      },
      dialogCancellation: {
        transaction: null,
        showDialog: false,
        cancelValidationText: "",
        func: () => {
          return this.dialogCancellation.transaction &&
            this.dialogCancellation.transaction["canceled"]
            ? this.reactivateFinancialTransaction()
            : this.cancelFinancialTransaction();
        },
      },
      showTable: true,
    };
  },

  created() {
    this.assignPeriodsAndShowCalendar();
  },

  watch: {
    period() {
      this.assignPeriodsAndShowCalendar();
    },

    customPeriod() {
      this.formatCustomPeriod();
    },

    clientKey() {
      this.assignPeriodsAndShowCalendar();
      this.panels = [];
    },
  },

  computed: {
    ...mapGetters(["token", "isConecta", "isReseller", "plans", "services"]),

    action() {
      const { action } = this.actionsToEditTransaction[this.steps];
      return action;
    },

    actionText() {
      const { text } = this.actionsToEditTransaction[this.steps];
      return text;
    },

    actionFunction() {
      const { func } = this.actionsToEditTransaction[this.steps];
      return func;
    },

    transactionToCancel() {
      const { transaction } = this.dialogCancellation;
      return transaction;
    },

    cancellationActionText() {
      if (this.transactionToCancel && this.transactionToCancel["canceled"]) {
        return "Reativar";
      }
      return "Confirmar cancelamento";
    },

    cancellationText() {
      if (this.transactionToCancel && this.transactionToCancel["canceled"]) {
        return "Reativar";
      }
      return "Cancelar";
    },
  },

  methods: {
    getDiscountPercentageValue,
    formatMoney,
    formatPeriod,
    initEndDateFormat,
    formatDate,

    getPlanSubscriptionType(transaction) {
      const subscriptionsType = {
        ANNUAL: "Anual",
        FLEX: "Mensal",
        TRIAL: "Trial",
        ONCE: "Único",
        QUARTELY: "Trimestral",
        SEMESTER: "Semestral",
        BIENNIUM: "Bimestral",
        EXTRA: "Extra",
      };
      let type = subscriptionsType[transaction];
      return type;
    },

    loadClientTransactions() {
      this.transactionPeriods.forEach((period) => {
        this.getTransactionsByMonth(period);
      });
    },

    showInstallmentsValues(transaction) {
      return `${transaction.installments}x de ${formatMoney(
        transaction.total_price / transaction.installments
      )}`;
    },

    nextStep() {
      const plan = this.activePlans.find(
        (plan) => plan["plan"].name === this.selectedPlan
      );

      this.newFinancialPlan["price"] = formatMoney(plan["price"]);

      this.newFinancialPlan["discount"] =
        (plan["discount"] * 100).toFixed(2) + "%";
      this.newFinancialPlan["newCompanyPlanKey"] = plan["key"];
      this.steps++;
    },

    totalValue(transactionsList) {
      const total = transactionsList.reduce((acc, transaction) => {
        if (transaction.total_price && !transaction.canceled) {
          acc += transaction.total_price;
        }
        return acc;
      }, 0);
      return total;
    },

    getTransactionsByMonth({ id, requestDate }) {
      this.loadTransaction = true;
      const url = `${process.env.VUE_APP_API_BASE_URL}/clients/${this.clientKey}/financial-transaction`;

      this.$axios
        .get(url, {
          params: {
            date: requestDate,
          },
          headers: {
            Authorization: this.token,
          },
        })
        .then(({ data }) => {
          this.transactionPeriods[id].transactions = data;
          this.transactionPeriods[id].totalValue = this.totalValue(data);
        })
        .catch(() => {
          this.showSnackBarFailure = true;
          this.transactionPeriods[id].transactions = null;
        })
        .finally(() => {
          this.loadTransaction = false;
        });
    },

    openEditFinancialDialog(transaction, id, index) {
      this.transactionId = id;
      this.transactionIndex = index;

      this.planToEdit = transaction["title"].trim();

      this.newFinancialPlan["init_date"] = this.formatDate(
        transaction["init_date"]
      );
      this.newFinancialPlan["end_date"] = transaction["end_date"]
        ? this.formatDate(transaction["end_date"])
        : "Transação aberta";
      this.newFinancialPlan["transctionKey"] = transaction["key"];

      //lista os nomes dos planos no select
      for (let i = 0; i < this.activePlans.length; i++) {
        if (this.isValidPlanForFinancialTransaction(this.activePlans[i])) {
          this.plansName.push(this.activePlans[i]["plan"].name);
        }
      }

      this.showEditFinancialDialog = true;
    },

    closeEditTransaction() {
      this.showEditFinancialDialog = false;
      this.selectedPlan = "";
      this.planToEdit = "";
      this.plansName = [];
      this.steps = 1;
    },

    editTransaction() {
      const url = `${process.env.VUE_APP_API_BASE_URL}/reseller/financial-transaction/${this.newFinancialPlan["transctionKey"]}/recalculate`;

      const data = {
        company_plan_key: this.newFinancialPlan["newCompanyPlanKey"],
      };

      const config = {
        headers: {
          Authorization: this.token,
        },
      };

      this.$axios
        .put(url, data, config)
        .then(({ data }) => {
          this.transactionPeriods[this.transactionIndex].transactions[
            this.transactionId
          ] = data;
          this.transactionPeriods[this.transactionIndex].totalValue =
            this.totalValue(
              this.transactionPeriods[this.transactionIndex].transactions
            );
          this.showSnackBarSuccess = true;
        })
        .catch(() => {
          this.showSnackBarFailure = true;
        })
        .finally(() => {
          this.closeEditTransaction();
        });
    },

    openDialog(transaction, id, index) {
      this.dialogCancellation.transaction = transaction;
      this.dialogCancellation.showDialog = true;

      this.transactionId = id;
      this.transactionIndex = index;
      this.planToEdit = transaction["title"].trim();
    },

    closeDialog() {
      this.dialogCancellation.showDialog = false;
      this.dialogCancellation.cancelValidationText = "";
    },

    cancelFinancialTransaction() {
      const url = `${process.env.VUE_APP_API_BASE_URL}/reseller/financial-transaction/${this.transactionToCancel["key"]}/cancel`;
      const config = {
        headers: { Authorization: this.token },
      };

      this.$axios
        .put(url, {}, config)
        .then(({ data }) => {
          this.transactionPeriods[this.transactionIndex].transactions[
            this.transactionId
          ].canceled = data.canceled;
          this.transactionPeriods[this.transactionIndex].totalValue =
            this.totalValue(
              this.transactionPeriods[this.transactionIndex].transactions
            );
          this.showSnackBarSuccess = true;
        })
        .catch(() => {
          this.showSnackBarFailure = true;
        })
        .finally(() => {
          this.closeDialog();
        });
    },

    reactivateFinancialTransaction() {
      const url = `${process.env.VUE_APP_API_BASE_URL}/reseller/financial-transaction/${this.transactionToCancel["key"]}/reactivate`;

      const config = {
        headers: {
          Authorization: this.token,
        },
      };

      this.$axios
        .put(url, {}, config)
        .then(({ data }) => {
          this.transactionPeriods[this.transactionIndex].transactions[
            this.transactionId
          ].canceled = data.canceled;
          this.transactionPeriods[this.transactionIndex].totalValue =
            this.totalValue(
              this.transactionPeriods[this.transactionIndex].transactions
            );
          this.showSnackBarSuccess = true;
        })
        .catch(() => {
          this.showSnackBarFailure = true;
        })
        .finally(() => {
          this.closeDialog();
        });
    },

    isValidPlanForFinancialTransaction(plan) {
      return (
        plan["plan"].name !== this.planToEdit &&
        plan["subscription_type"] !== this.SUBSCRIPTIONS.ANNUAL &&
        plan["subscription_type"] !== this.SUBSCRIPTIONS.TRIAL &&
        plan["subscription_type"] !== this.SUBSCRIPTIONS.ONCE
      );
    },

    formatCustomPeriod() {
      this.transactionPeriods = [];
      let firstMonth = moment(this.customPeriod[0])
        .locale("pt-br")
        .format("YYYY-MM");
      let currentMonth = moment(this.customPeriod[1])
        .locale("pt-br")
        .format("YYYY-MM");
      let auxiliary = currentMonth;

      let i = 0;
      while (currentMonth >= firstMonth) {
        auxiliary = moment(currentMonth)
          .locale("pt-br")
          .format("MMMM [de] YYYY");
        auxiliary = auxiliary
          .substring(0, 1)
          .toUpperCase()
          .concat(auxiliary.substring(1));
        this.transactionPeriods.push({
          id: i,
          label: auxiliary,
          requestDate: currentMonth,
        });
        currentMonth = moment(currentMonth)
          .locale("pt-br")
          .add(-1, "months")
          .format("YYYY-MM");
        i++;
      }
    },

    customFilter(item, queryText) {
      const textOne = item.name.toLowerCase().trim();
      const searchText = queryText.toLowerCase().trim();

      return textOne.indexOf(searchText) > -1;
    },

    assignPeriodsAndShowCalendar() {
      [this.transactionPeriods, this.showCalendar] = this.formatPeriod(
        this.period
      );
    },
  },
};
</script>
