<template>
  <HeaderDialog
    :show="show"
    id="contract_dialog"
    @close="$emit('close')"
    :title="clientName"
    subtitle="Detalhes do contrato"
    read-only
  >
    <template v-slot:body v-if="hasUser">
      <p>
        <span class="font-weight-bold">Usuário:</span>
        {{ client.contract.user.name }}
      </p>
      <p>
        <span class="font-weight-bold">E-mail:</span>
        {{ client.contract.user.email }}
      </p>
      <p><span class="font-weight-bold">IP:</span> {{ client.contract.ip }}</p>
      <p>
        Aceito em
        <span class="font-weight-bold">{{
          client.contract.datetime
            ? formatDateAndHour(client.contract.datetime)
            : formatDateAndHour(client.contract_date)
        }}</span>
      </p>
    </template>
  </HeaderDialog>
</template>
<script>
import { formatDateAndHour } from "@/helpers/services/utils";
import HeaderDialog from "@/components/base/HeaderDialog";
export default {
  components: { HeaderDialog },
  props: {
    show: { type: Boolean, required: true },
    client: { type: Object, required: true },
    clientName: { type: String, required: true },
  },
  methods: {
    formatDateAndHour,
  },
  computed: {
    hasUser() {
      return this.client.contract !== null ? true : false;
    },
  },
};
</script>
