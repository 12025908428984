<template>
  <v-card :class="`mx-5 my-5 elevation-0 pb-0`" outlined>
    <v-expansion-panels flat v-model="handleExpand">
      <v-expansion-panel>
        <v-expansion-panel-header
          :class="`px-5 py-1 d-flex ${
            !$vuetify.breakpoint.xs || 'flex-column'
          }`"
        >
          <v-card-title class="px-0"> Contagem de uso </v-card-title>
          <v-spacer />
          <div class="d-flex justify-end mr-5" v-if="showExpand">
            <v-btn
              :disabled="loading"
              :loading="loading"
              icon
              class="mr-4"
              @click.stop="getFeatureUsageCount(period.value)"
            >
              <v-icon>mdi-reload</v-icon>
            </v-btn>
            <SelectPeriod
              :loading="loading"
              @change="period = $event"
              :value="period"
            />
          </div>
        </v-expansion-panel-header>
        <v-divider v-if="showExpand" />
        <v-expand-transition>
          <v-expansion-panel-content
            class="d-flex mt-5"
            v-if="showExpand"
            transition="scroll-y-transition"
          >
            <v-row align-content="center" class="ma-0">
              <v-col align="center" v-for="(item, i) in slats" :key="i">
                <Slat
                  :loading="loading"
                  :value="item.value"
                  description="Total de utilizações"
                  :label="item.label"
                  :color="item.color"
                  text-class="white--text"
                >
                  <template #icon v-if="!$vuetify.breakpoint.mobile"
                    ><Logo
                      height="50"
                      class="mx-auto"
                      :product="item.product"
                      white
                  /></template>
                </Slat>
              </v-col>
            </v-row>
            <v-data-table
              :headers="headers"
              :loading="loading"
              :items="items"
              sort-by="value"
              sort-desc
              class="elevation-1 mt-5"
              width="100%"
              fixed-header
              height="calc(70vh - 220px)"
              :items-per-page="itemsPerPage"
              :footer-props="{ itemsPerPageOptions: itemsOptions }"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td class="d-flex align-center">
                    <div>
                      <Logo height="30" :product="item.product" />
                    </div>
                    {{ keyNames[item.key] }}
                  </td>
                  <td>{{ item.value }}</td>
                </tr>
              </template></v-data-table
            >
          </v-expansion-panel-content>
        </v-expand-transition>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>
<script>
import Slat from "@/components/general/Slat.vue";
import SelectPeriod from "@/components/general/SelectPeriod.vue";
import { mapGetters } from "vuex";
import { paging, tableHeight } from "@/helpers/variables/tablesDefault";
import { productNames } from "@/helpers/variables/translateString";
import { subtractMonths } from "@/helpers/services/utils";
export default {
  name: "FeatureUsageCount",
  components: { Slat, SelectPeriod },
  props: { clientKey: { type: String, required: true } },
  data() {
    return {
      productNames,
      expand: undefined,
      showExpand: false,
      loading: false,
      featureUsageCount: undefined,
      headers: [
        {
          text: "Funcionalidade",
          value: "functionality",
          width: "50%",
          sortable: false,
        },
        { text: "Número de utilizações", value: "value" },
      ],
      itemsPerPage: paging.perPage,
      itemsOptions: paging.All,
      tableHeight,
      keyNames: {
        // CONTROL
        activate_sso: "Ativar o Control",
        add_vacation_to_user: "Atribuir modo férias",
        change_own_password: "Alterar a própria senha",
        change_user_password: "Alterar senha de usuário",
        deleted_groups: "Deletar turnos",
        disable_sso: "Desativar o Control",
        edit_group_rules: "Editar regras de turno",
        group_add_or_remove_users: "Gerenciar participantes em turnos",
        receive_user_single_access: "Receber acesso único",
        remove_vacation_from_user: "Remover modo férias",
        user_add_or_remove_groups:
          "Adicionar ou remover usuário de um turno a partir do perfil",
        user_request_password_change: "Solicitar troca de senha",
        user_request_single_access: "Solicitar acesso único",
        // SIGN
        new_users_signature:
          "(Sistema) Aplicar assinatura padrão para novos usuários",
        apply_email_signature: "Aplicar assinatura de e-mail",
        apply_secondary_email_signature:
          "Aplicar assinatura de e-mail secundário",
        edit_tags: "Editar informações da empresa",
        edit_user_tags: "Editar informações de usuário",
        // SUITE
        user_delegates: "Delegação de acesso à caixa de e-mail",
        verify_phone: "Verificação de telefone",
      },
      period: { text: "Ùltimo mês", value: subtractMonths(1) },
    };
  },
  computed: {
    ...mapGetters(["token"]),

    slats() {
      return [
        {
          value: this.totalUsageSuite,
          color: "primarySuite",
          label: "Conecta Suite",
          product: "conecta_suite",
        },
        {
          value: this.totalUsageSign,
          color: "primarySign",
          label: "Assinaturas de e-mail",
          product: "conecta_sign",
        },
        {
          value: this.totalUsageControl,
          color: "primaryControl",
          label: "Conecta Control",
          product: "conecta_control",
        },
      ];
    },

    handleExpand: {
      get() {
        return this.expand;
      },
      set(value) {
        this.expand = value;
        this.showExpand = value === 0;
      },
    },
    totalUsageSuite() {
      if (this.featureUsageCount) {
        const { conecta_suite } = this.featureUsageCount;
        return conecta_suite.total_usage;
      }

      return 0;
    },

    totalUsageSign() {
      if (this.featureUsageCount) {
        const { conecta_sign } = this.featureUsageCount;
        return conecta_sign.total_usage;
      }

      return 0;
    },

    totalUsageControl() {
      if (this.featureUsageCount) {
        const { conecta_control } = this.featureUsageCount;
        return conecta_control.total_usage;
      }

      return 0;
    },

    items() {
      if (this.featureUsageCount) {
        const items = Object.keys(this.featureUsageCount).reduce(
          (acc, productKey) => {
            const productUsageCount = this.featureUsageCount[productKey];
            delete productUsageCount["total_usage"];

            const values = Object.keys(productUsageCount).map((featureKey) => ({
              key: featureKey,
              value: productUsageCount[featureKey],
              product: productKey,
            }));

            return [...acc, ...values];
          },
          []
        );

        return items;
      }

      return [];
    },
  },
  methods: {
    getFeatureUsageCount(fromDate = subtractMonths(1)) {
      this.loading = true;

      const url = `${process.env.VUE_APP_API_BASE_URL}/clients/${this.clientKey}/feature-usage-count?from_date=${fromDate}`;

      this.$axios
        .get(url, {
          headers: {
            Authorization: this.token,
          },
        })
        .then(({ data }) => {
          this.featureUsageCount = data;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    period(value) {
      this.getFeatureUsageCount(value.value);
    },
    showExpand(value) {
      if (value && !this.featureUsageCount) {
        this.getFeatureUsageCount();
      }
    },

    $route(newRoute, oldRoute) {
      if (newRoute.query.key !== oldRoute.query.key) {
        this.getFeatureUsageCount();
      }
    },
  },
};
</script>
<style></style>
