<template>
  <SimpleDialog
    :show="show"
    title="Confirmar plano de pagamento"
    id="module_price_changes"
    @close="$emit('close')"
    @action="$emit('save', showAdvanced)"
    :custom-buttom="isConecta && isTrial && !showAdvanced"
    custom-buttom-text="Avançado"
    :customButtonIcon="'mdi-menu-right'"
    @left-button="showAdvanced = true"
  >
    <template v-slot:body>
      Deseja confirmar a alteração de status do plano?

      <p v-if="showAdvanced && isTrial" class="mx-0 px-0">
        <v-card-title
          class="ma-0 pa-0 font-weight-bold text-body-1 mt-8 mb-2 text-link"
          @click="showAdvanced = false"
        >
          <v-icon v-text="'mdi-menu-down'" dense />
          Avançado
        </v-card-title>
        <v-alert text dense class="caption" icon="mdi-alert" color="warning"
          >Visível apenas para funcionários da Conecta</v-alert
        >
        <v-checkbox
          v-model="changeTrial"
          :true-value="true"
          :false-value="false"
          dense
          hide-details
          color="accent"
          class="px-0 mx-3 caption"
          :label="`Trial termina em ${endTrial} (14 dias de teste grátis)`"
        />
      </p>
    </template>
  </SimpleDialog>
</template>
<script>
import SimpleDialog from "@/components/base/SimpleDialog";
import { getTheEndOfTrial, formatDate } from "@/helpers/services/utils";
import { mapGetters } from "vuex";
export default {
  name: "ConfirmPlanSubscription",
  components: { SimpleDialog },
  props: {
    show: { type: Boolean, required: true },
    loading: { type: Boolean, default: false },
    isTrial: { type: Boolean, default: false },
  },
  data() {
    return {
      showAdvanced: false,
      changeTrial: false,
    };
  },
  computed: {
    ...mapGetters(["isConecta"]),

    endTrial() {
      return formatDate(getTheEndOfTrial());
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.showAdvanced = false;
        this.changeTrial = false;
      }
    },
  },
  methods: {
    getTheEndOfTrial,
    formatDate,
  },
};
</script>
