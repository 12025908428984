import axios from "axios";

export function changeClientModuleStatus(module_key, payload) {
  let url =
    process.env.VUE_APP_API_BASE_URL + `/customers/products/${module_key}`;
  let token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: "Bearer " + token },
  };
  return axios.put(url, payload, config);
}
