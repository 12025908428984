<template>
  <SimpleDialog
    id="add_client_to_reseller"
    title="Atribuir um Cliente a um Revendedor"
    :show="showDialog"
    width="800"
    custom-footer
    @close="showDialog = false"
  >
    <template v-slot:body>
      <v-row class="d-flex align-center justify-center mb-2">
        <v-col sm="1" md="1" lg="1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                icon
                v-on="{ ...tooltip }"
                color="accent"
                class="mb-8"
                large
                @click="removeReseller = !removeReseller"
                :disabled="resellerKeyIsConectaSuite"
              >
                <v-icon v-text="buttonIcon" color="accent" />
              </v-btn>
            </template>
            <span v-if="removeReseller">Atribuir novo revendedor</span>
            <span v-else>Atribuir para Conecta Nuvem</span>
          </v-tooltip>
        </v-col>
        <v-col sm="11" md="11" lg="11">
          <v-autocomplete
            v-model="selectedClient"
            :items="allClients"
            :loading="loading"
            :search-input.sync="searchClient"
            hide-no-data
            hide-selected
            item-text="name"
            label="Nome do cliente"
            placeholder="Digite o nome de um cliente"
            return-object
            outlined
            :disabled="edit"
            color="secondary"
          ></v-autocomplete>
          <v-autocomplete
            v-model="selectedReseller"
            :items="allResellers"
            :loading="loading"
            :search-input.sync="searchReseller"
            hide-no-data
            hide-selected
            item-text="name"
            label="Nome do parceiro"
            placeholder="Digite o nome de um parceiro revendedor"
            return-object
            outlined
            :disabled="removeReseller"
            color="secondary"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="d-flex align-center justify-center ma-0 pb-0">
        <v-btn
          color="accent"
          class="elevation-0 text-body-1 text-none font-weight-medium"
          large
          :disabled="clientResellerNotChanged"
          @click="confirmResellerChange = true"
        >
          <v-icon v-text="'mdi-account-child'" class="mr-2" />
          {{
            removeReseller
              ? "Atribuir cliente para Conecta Nuvem"
              : "Atribuir cliente a este Revendedor"
          }}
        </v-btn>
      </v-row>
      <SimpleDialog
        id="confirm_reseller_change"
        title="Confirmar troca de Revendedor"
        :show="confirmResellerChange"
        width="700"
        @close="confirmResellerChange = false"
        custom-footer
      >
        <template v-slot:body>
          <p
            class="mx-4 my-6 font-weight-medium text-body-1"
            style="line-height: 28px"
          >
            Tem certeza que desja atualizar o revendedor deste cliente de
            <span class="font-weight-bold">{{ currentlyResellerName }}</span>
            para <span class="font-weight-bold">{{ newResellerName }}</span
            >?
          </p>

          <v-row class="justify-center">
            <v-btn
              color="accent"
              class="elevation-0 text-body-1 text-none font-weight-medium mt-4"
              large
              :disabled="clientResellerNotChanged"
              @click="setResellerToClient()"
            >
              <v-icon
                v-text="removeReseller ? 'mdi-close' : 'mdi-account-child'"
                class="mr-2"
              />
              {{
                removeReseller
                  ? "Remover atribuição deste Revendedor"
                  : "Atribuir cliente a este Revendedor"
              }}
            </v-btn>
          </v-row>
        </template>
      </SimpleDialog>
    </template>
  </SimpleDialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import SimpleDialog from "@/components/base/SimpleDialog";
export default {
  components: { SimpleDialog },
  props: {
    show: { type: Boolean, required: true },
    client_key: { type: String, default: "" },
    client_reseller_key: { type: String, default: "" },
    edit: { type: Boolean, default: true },
  },
  data() {
    return {
      loading: true,
      confirmResellerChange: false,
      removeReseller: false,
      allClients: [],
      allResellers: [],
      selectedClient: {},
      selectedReseller: {},
      searchClient: "",
      searchReseller: "",
    };
  },
  async beforeMount() {
    await this.getResellers();
    this.loadInputInfo();
    this.loading = false;
  },
  computed: {
    ...mapGetters(["clients", "resellers", "conectaSuiteResellerToken"]),

    showDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("closeAddClientReseller");
      },
    },
    buttonIcon() {
      return this.removeReseller ? "mdi-link-off" : "mdi-link";
    },
    resellerKeyIsConectaSuite() {
      return this.client_reseller_key === this.conectaSuiteResellerToken;
    },
    clientResellerNotChanged() {
      return (
        this.client_reseller_key === this.selectedReseller.cs_token &&
        !this.removeReseller
      );
    },
    currentlyResellerName() {
      return this.allResellers.find(
        (reseller) => reseller.cs_token === this.client_reseller_key
      ).name;
    },
    newResellerName() {
      return this.removeReseller ? "Conecta Nuvem" : this.selectedReseller.name;
    },
  },
  watch: {
    "$route.fullPath"() {
      this.loadInputInfo();
    },
    showDialog() {
      if (!this.showDialog) {
        this.resetvariables();
      } else {
        this.loadInputInfo();
      }
    },
  },
  methods: {
    ...mapActions(["getResellers", "updateClientReseller"]),

    resetvariables() {
      this.confirmResellerChange = false;
      this.removeReseller = false;
      this.selectedClient = {};
      this.selectedReseller = {};
      this.searchClient = "";
      this.searchReseller = "";
    },

    loadInputInfo() {
      this.allClients = this.clients.slice();
      this.allResellers = this.resellers.slice();
      this.selectedClient = this.allClients.find(
        (client) => client.key === this.client_key
      );
      this.selectedReseller =
        this.allResellers.find(
          (reseller) => reseller.cs_token === this.client_reseller_key
        ) || {};
    },

    async setResellerToClient() {
      let payload = {};

      if (this.removeReseller) {
        payload["reseller_token"] = this.conectaSuiteResellerToken;
      } else {
        payload["reseller_token"] = this.selectedReseller.cs_token;
      }

      const info = {
        client_key: this.client_key,
        payload: payload,
      };

      await this.updateClientReseller(info)
        .then(() => {
          this.showDialog = false;
          this.confirmResellerChange = false;
          if (this.removeReseller) {
            this.$emit("showSuccessRemoveReseller", this.currentlyResellerName);
          } else {
            this.$emit(
              "showSuccessResellerChangeMessage",
              this.newResellerName
            );
          }
        })
        .catch((e) => {
          this.showDialog = false;
          this.confirmResellerChange = false;
          if (this.removeReseller) {
            this.$emit("showFailureRemoveReseller");
          } else {
            this.$emit("showErrorResellerChangeMessage");
          }
          console.error("setResellerToClient(): ", e);
        });
    },
  },
};
</script>
